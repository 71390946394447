import Bugsnag, { Client,
	browserContext,
	browserHandledRejectionBreadcrumbs,
	browserNotifyUnhandledExceptions,
	browserNotifyUnhandledRejections,
	consoleBreadcrumbs,
	errorBreadcrumbs,
	limitEvents,
	stringifyValues } from '@birchill/bugsnag-zero';

const metaValue = ( name: string ): string | null => {
	if ( !document || !document.head ) {
		return null;
	}

	const metaTag: HTMLMetaElement | null = document.head.querySelector( 'meta[name="' + name + '"][content]' );
	if ( !metaTag || !metaTag.content ) {
		return null;
	}

	return metaTag.content;
};

export const bugsnagClient = Bugsnag.start( {
	apiKey: metaValue( 'mrh:bugsnag' ) || '',
	appType: metaValue( 'mrh:theme' ) || 'unknown theme',
	collectUserIp: false,
	enabledReleaseStages: [
		'production',
	],
	releaseStage: metaValue( 'mrh:stage' ) || 'production',
	plugins: [
		browserContext,
		browserHandledRejectionBreadcrumbs,
		browserNotifyUnhandledExceptions,
		browserNotifyUnhandledRejections,
		consoleBreadcrumbs,
		errorBreadcrumbs,
		limitEvents( 10 ),
		stringifyValues,
	],
	onError: ( event ) => {
		if ( event.exceptions.some( ( x ) => {
			if ( !x.message ) {
				return false;
			}

			// Ignore bogus Outlook error
			return x.message.includes( 'Object Not Found Matching Id' ) ||
				// Ignore bogus Chrome error
				x.message.includes( '__gCrWeb' );
		} ) ) {
			return false;
		}

		// Ignore bogus Outlook error
		if ( `${event.metaData?.unhandledrejection}`.includes( 'Object Not Found Matching Id' ) ) {
			return false;
		}

		// Update Context
		const theme = metaValue( 'mrh:theme' );
		if ( theme ) {
			event.context = event.context + ' - ' + theme;
		}

		// Update Meta Data
		const id = metaValue( 'mrh:id' );
		if ( id && theme ) {
			event.metaData = event.metaData || {};
			event.metaData.site = {
				id: id,
				theme: theme,
			};
		}

		const target = metaValue( 'mrh:browser-target' );
		if ( target ) {
			event.metaData = event.metaData || {};
			event.metaData['js-bundle'] = {
				target: target,
			};
		}
	},
} );

declare global {
	interface Window {
		bugsnagClient: Client
	}
}

window.bugsnagClient = bugsnagClient;
